import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/zeit/54654bf8/website/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { useState } from 'react';
import { Playground } from 'docz';
import DebounceClass from '@mini-code/base-func/debounce';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "debounce--防抖"
    }}>{`Debounce / 防抖`}</h1>
    <p>{`为了避免在短时间内重复调用同一个函数而导致性能或其他不可控问题，basic-helper 提供函数防抖。`}</p>
    <h2 {...{
      "id": "使用"
    }}>{`使用`}</h2>
    <Playground __position={0} __code={'() => {\n  const { exec } = new DebounceClass()\n  const [count, setCount] = useState(0)\n  const plus = () => {\n    setCount(count + 1)\n  }\n  return (\n    <div>\n      <div>点击次数: {count}</div>\n      <hr />\n      <span\n        className=\"btn theme mr10\"\n        onClick={e => {\n          plus()\n        }}\n      >\n        点击 + 1\n      </span>\n      <span\n        className=\"btn red mr10\"\n        onClick={e => {\n          exec(plus, 1000)\n        }}\n      >\n        一秒之内的点击 + 1\n      </span>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      DebounceClass
    }} mdxType="Playground">
  {() => {
        const {
          exec
        } = new DebounceClass();
        const [count, setCount] = useState(0);

        const plus = () => {
          setCount(count + 1);
        };

        return <div>
          <div>点击次数: {count}</div>
          <hr />
          <span className="btn theme mr10" onClick={e => {
            plus();
          }}>点击 + 1</span>
          <span className="btn red mr10" onClick={e => {
            exec(plus, 1000);
          }}>一秒之内的点击 + 1</span>
        </div>;
      }}
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      